import { charts_colors, ChartsColorsKeys } from '@shared/components/Charts/colors';
import { TitleTaggedComponent, TitleTaggedComponentItems } from '@shared/components/TitleTaggedComponent';
import {
  generateHourUnixTimes,
  generateDayUnixTimes,
  generateWeekUnixTimes,
  generateMonthUnixTimes,
  formatWeekDate,
} from '@shared/functions/date';
import { Unit } from '@shared/models/Unit';
import { Select, Typography, Spin } from 'antd';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import {
  ResponsiveContainer,
  LineChart,
  Line as RechartsLine,
  CartesianGrid,
  XAxis,
  Label,
  YAxis,
  Legend,
  PieChart,
  Pie,
  Cell,
  Tooltip as RechartsToolTip,
} from 'recharts';
import dayjs, { Dayjs } from 'dayjs';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { useObj } from '@shared/hooks/useObj/useObj';
import { periodSettingToIso8601Range } from '../PeriodSettingEngine/functions';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Camera } from 'api/cameras';
import {
  Countdata,
  countDataGetRequest,
  KeyMetric,
  sumAgeGroups,
  sumPersonDataListByAgeGroups,
  sumPersonDataListByGender,
  TimeMetric,
} from 'api/countdata';
import { saveAs } from 'file-saver';
import { useCurrentPng } from 'recharts-to-png';
import { jsonToCsv, toPeriodSettingValue } from '@shared/functions';
import { Formatter } from 'recharts/types/component/DefaultLegendContent';
import { useMetrics } from 'hooks/useMetrics/useMetrics';
import { Age, ageToJP } from '@shared/models/Age';
import { all_genders } from '@shared/models/Gender';
import { all_directions, Direction } from '@shared/models/Direction';
import { ISO8601ToUnix } from '@shared/models/ISO8601';
import { UnixToJapanDateTime } from '@shared/models/UNIX';
import { Line } from '@shared/models/Line';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { NarrowDown } from '@shared/models/NarrowDown';

const metricsToCsvJson = (
  ages: Age[],
  metrics?: Countdata[],
  selected_direction?: Direction,
  narrow_down?: NarrowDown,
) => {
  const new_json: Record<string, number>[] = [];
  metrics?.forEach((metric) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const jo: Record<string, any> = { time: UnixToJapanDateTime(ISO8601ToUnix(metric.time)) };

    if (!narrow_down) {
      all_genders.forEach((gender) => {
        const directions = selected_direction ? [selected_direction] : all_directions;
        directions.forEach((direction) => {
          Object.entries(metric.metrics[direction][gender]).forEach(([age_key, age_value]) => {
            const custom_key = `${direction}_${gender}_${age_key}`;
            jo[custom_key] = age_value;
          });
        });
      });
    } else if (narrow_down === 'direction') {
      const directions = selected_direction ? [selected_direction] : all_directions;
      directions.forEach((direction) => {
        jo[direction] =
          sumAgeGroups(metric.metrics[direction].male, ages) + sumAgeGroups(metric.metrics[direction].female, ages);
      });
    } else if (narrow_down === 'age') {
      ages.forEach((age) => {
        const directions = selected_direction ? [selected_direction] : all_directions;
        jo[age] = directions.reduce((sum, direction) => {
          return sum + metric.metrics[direction].male[age] + metric.metrics[direction].female[age];
        }, 0);
      });
    } else if (narrow_down === 'gender') {
      const directions = selected_direction ? [selected_direction] : all_directions;
      all_genders.forEach((gender) => {
        jo[gender] = directions.reduce((sum, direction) => {
          return sum + sumAgeGroups(metric.metrics[direction][gender], ages);
        }, 0);
      });
    }
    new_json.push(jo);
  });
  return new_json;
};

const pieLegendFormatter: Formatter = (value, entry) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const percent = (entry.payload as any)?.percent;
  if (typeof percent !== 'number') {
    return value;
  }

  return `${value} ${(percent * 100).toFixed(0)}%`;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
      style={{ fontSize: 'small' }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const getTicks = (unit: Unit, start: Dayjs): (number | string)[] => {
  switch (unit) {
    case 'hour':
      return generateHourUnixTimes(start);
    case 'day':
      return generateDayUnixTimes(start);
    case 'week':
      return generateWeekUnixTimes(start);
    case 'month':
      return generateMonthUnixTimes(start);
    default:
      return [];
  }
};

const tickFormatter = (unit: Unit, start_date?: Dayjs) => (datapoint: number) => {
  const date = dayjs.unix(datapoint);
  let result = '';
  if (unit === 'hour') {
    result = date.format('mm');
    // 最後のデータだけ00を60に変更する
    if (result === '00' && start_date) {
      // 秒以下を切り捨て
      const start = dayjs(start_date).startOf('hour').unix();
      if (datapoint >= start + 60 * 60) {
        result = '60';
      }
    }
  } else if (unit === 'day') {
    result = date.format('HH');
    // 最後のデータだけ00を24に変更する
    if (result === '00' && start_date) {
      // 時以下を切り捨て
      const start = dayjs(start_date).startOf('day').unix();
      if (datapoint >= start + 60 * 60 * 24) {
        result = '24';
      }
    }
  } else if (unit === 'week') {
    result = formatWeekDate(date);
  } else if (unit === 'month') {
    result = date.format('DD');
  }
  return result;
};

const unitToXAxisLabel = (unit: Unit) => {
  switch (unit) {
    case 'hour':
      return '(分)';
    case 'day':
      return '(時)';
    case 'week':
      return undefined;
    case 'month':
      return '(日)';
    default:
      return undefined;
  }
};

export interface UrbanInfoRef {
  downloadCsv(selectedDirection?: Direction, narrow_down?: NarrowDown): void;
  downloadPng(): void;
  isLoading(): boolean;
  getCurrentDirection(): Direction | undefined;
}

type DataType = 'count' | 'gender' | 'age';

export interface CameraDetailPanelProps {
  period_setting: PeriodSetting;
  age_setting: AgeSetting;
  line_info: Line;
  default_direction?: Direction;
  camera: Camera;
}
export const CameraDetailPanel = forwardRef<UrbanInfoRef, CameraDetailPanelProps>(
  ({ period_setting, age_setting, camera, line_info, default_direction }, ref) => {
    const { obj: period_setting_form, updateObj: updatePeriodSettingForm } = useObj<PeriodSetting>({
      ...period_setting,
    });
    const direction_options = [
      { value: 'LR', label: line_info.lr_name },
      { value: 'RL', label: line_info.rl_name },
    ];
    const [direction, setDirection] = useState<Direction>(default_direction ?? 'LR');

    const { start: initial_start, end: initial_end } = useMemo(() => {
      return periodSettingToIso8601Range(period_setting_form);
    }, [period_setting_form]);

    const { metrics, loadMetrics } = useMetrics({
      unit: period_setting_form.unit,
      start: initial_start,
      end: initial_end,
      line_no: line_info.line_number,
      camera,
    });
    // 時系列データ用のmetrics
    const count_metrics: TimeMetric[] | undefined = useMemo(() => {
      return metrics?.map((m) => {
        return {
          time: ISO8601ToUnix(m.time),
          value:
            sumAgeGroups(m.metrics[direction].male, age_setting.ages) +
            sumAgeGroups(m.metrics[direction].female, age_setting.ages),
        };
      });
    }, [metrics, direction, age_setting.ages]);
    // 円グラフ(性別)用のmetrics
    const gender_metrics: KeyMetric[] | undefined = useMemo(() => {
      return metrics
        ? [
            {
              key: 'male',
              name: '男性',
              value: sumPersonDataListByGender('male', direction, age_setting.ages, metrics),
            },
            {
              key: 'female',
              name: '女性',
              value: sumPersonDataListByGender('female', direction, age_setting.ages, metrics),
            },
          ]
        : undefined;
    }, [metrics, direction, age_setting.ages]);
    // 円グラフ(年代)用のmetrics
    const age_metrics: KeyMetric[] | undefined = useMemo(() => {
      if (!metrics) return undefined;
      return age_setting.ages.map((age) => {
        return {
          key: age,
          name: ageToJP(age),
          value: sumPersonDataListByAgeGroups(age, direction, metrics),
        };
      });
    }, [metrics, age_setting.ages, direction]);
    const [getCountPng, { ref: count_ref, isLoading: count_is_loading }] = useCurrentPng();
    const [getSexPng, { ref: gender_ref, isLoading: gender_is_loading }] = useCurrentPng();
    const [getAgePng, { ref: age_ref, isLoading: age_is_loading }] = useCurrentPng();

    const start_date = typeof count_metrics?.[0]?.time === 'number' ? dayjs.unix(count_metrics[0].time) : undefined;

    const ticks = useMemo(() => {
      return start_date ? getTicks(period_setting_form.unit, start_date) : [];
    }, [period_setting_form.unit, start_date]);

    const onDirectionChange = (new_direction: Direction) => {
      setDirection(new_direction);
      const { start, end } = periodSettingToIso8601Range(period_setting);
      const base_request = {
        camera_id: camera.camera_id,
        start,
        end,
        unit: period_setting.unit,
        line_no: line_info.line_number,
        direction,
      };
      loadAll(base_request);
    };
    const onPeriodSettingChange = (period_setting: PeriodSetting) => {
      updatePeriodSettingForm(period_setting);
      const { start, end } = periodSettingToIso8601Range(period_setting);
      const base_request = {
        camera_id: camera.camera_id,
        start,
        end,
        unit: period_setting.unit,
        line_no: line_info.line_number,
        direction,
      };
      loadAll(base_request);
    };
    const loadAll = (request: Omit<countDataGetRequest, 'data_type'>) => {
      loadMetrics(request);
    };

    const downloadCSV = (selected_direction?: Direction, narrow_down?: NarrowDown) => {
      const line_name = selected_direction == 'LR' ? line_info.lr_name : line_info.rl_name;
      const period = toPeriodSettingValue(period_setting_form).replace(/\//g, '-').replace(/:/g, '');
      const totalling =
        narrow_down == 'direction'
          ? '_(方向)'
          : narrow_down == 'age'
          ? '_(年齢)'
          : narrow_down == 'gender'
          ? '_(性別)'
          : '';
      const file_name = camera.camera_name + '_' + period + '_' + line_name + totalling + '.csv';
      const metrics_json = metricsToCsvJson(age_setting.ages, metrics, selected_direction, narrow_down);
      const csv = jsonToCsv(metrics_json);
      // BOMの設定
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csv], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, file_name);
    };

    const downloadAllPng = async () => {
      await Promise.all(
        ['count', 'gender', 'age'].map((type) => {
          return downloadPng(type as DataType);
        }),
      );
    };

    const downloadPng = async (type: DataType) => {
      let png: string | undefined = undefined;
      let file_name = '';
      if (type === 'count') {
        png = await getCountPng();
        file_name = 'count_graph.png';
      } else if (type === 'gender') {
        png = await getSexPng();
        file_name = 'gender_graph.png';
      } else if (type === 'age') {
        png = await getAgePng();
        file_name = 'age_graph.png';
      }
      if (!png) return;
      saveAs(png, file_name);
    };

    useImperativeHandle(ref, () => ({
      downloadCsv(selected_direction, narrow_down) {
        downloadCSV(selected_direction, narrow_down);
      },
      downloadPng() {
        downloadAllPng();
      },
      isLoading() {
        return count_is_loading || gender_is_loading || age_is_loading;
      },
      getCurrentDirection() {
        return direction;
      },
    }));

    useEffect(() => {
      updatePeriodSettingForm(period_setting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePeriodSettingForm, camera]);

    useEffect(() => {
      loadMetrics({
        unit: period_setting_form.unit,
        start: initial_start,
        end: initial_end,
        line_no: line_info.line_number,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [direction]);

    useEffect(() => {
      const { start, end } = periodSettingToIso8601Range(period_setting);
      const base_request = {
        camera_id: camera.camera_id,
        start,
        end,
        unit: period_setting.unit,
        line_no: line_info.line_number,
        direction,
      };
      loadAll(base_request);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [line_info]);

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ height: 76 }}>
          <TitleTaggedComponentItems
            items={[
              {
                title: '表示期間',
                children: <PeriodSettingEngine form={period_setting_form} updateForm={onPeriodSettingChange} />,
              },
              {
                title: '方向',
                children: (
                  <Select
                    style={{ width: '100%' }}
                    value={direction}
                    onChange={onDirectionChange}
                    options={direction_options}
                  />
                ),
              },
            ]}
          />
        </div>
        <div style={{ height: 'calc((100% - 76px) * 0.34)' }}>
          <TitleTaggedComponent title='通行者数' direction='vertical'>
            <ResponsiveContainer>
              {count_metrics ? (
                <LineChart data={count_metrics} margin={{ right: 15, bottom: 15 }} ref={count_ref}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='time'
                    type='number'
                    domain={['dataMin', 'dataMax']}
                    ticks={ticks}
                    tickFormatter={tickFormatter(period_setting_form.unit, start_date)}
                  >
                    <Label
                      value={unitToXAxisLabel(period_setting_form.unit)}
                      offset={-10}
                      position='insideBottomRight'
                    />
                  </XAxis>
                  <YAxis>
                    <Label value='(人)' offset={0} position='insideTopLeft' />
                  </YAxis>
                  <RechartsToolTip
                    labelFormatter={(label: number) => {
                      const date = dayjs.unix(label);
                      return <Typography.Text>{date.format('YYYY/MM/DD HH:mm')}</Typography.Text>;
                    }}
                  />
                  <RechartsLine type='linear' dataKey='value' stroke={charts_colors.linear_line} />
                </LineChart>
              ) : (
                <Spin />
              )}
            </ResponsiveContainer>
          </TitleTaggedComponent>
        </div>
        <div style={{ height: 'calc((100% - 76px) * 0.33)' }}>
          <TitleTaggedComponent title='性別割合' direction='vertical'>
            <ResponsiveContainer>
              {gender_metrics ? (
                <PieChart ref={gender_ref}>
                  <Pie
                    data={gender_metrics}
                    cx='50%'
                    cy='50%'
                    labelLine={false}
                    label={renderCustomizedLabel}
                    dataKey='value'
                    startAngle={90}
                    endAngle={450}
                  >
                    {gender_metrics?.map((gender_metric, index) => (
                      <Cell key={`cell-${index}`} fill={charts_colors[gender_metric.key as ChartsColorsKeys]} />
                    ))}
                  </Pie>
                  <Legend align='right' verticalAlign='middle' layout='vertical' formatter={pieLegendFormatter} />
                </PieChart>
              ) : (
                <Spin />
              )}
            </ResponsiveContainer>
          </TitleTaggedComponent>
        </div>
        <div style={{ height: 'calc((100% - 76px) * 0.33)' }}>
          <TitleTaggedComponent title='年代割合' direction='vertical'>
            <ResponsiveContainer>
              {age_metrics ? (
                <PieChart ref={age_ref}>
                  <Pie
                    data={age_metrics}
                    cx='50%'
                    cy='50%'
                    labelLine={false}
                    label={renderCustomizedLabel}
                    dataKey='value'
                    startAngle={90}
                    endAngle={-270}
                  >
                    {age_metrics?.map((age_metric, index) => {
                      const fill = charts_colors[age_metric.key as ChartsColorsKeys];
                      return <Cell key={`cell-${index}`} fill={fill} />;
                    })}
                  </Pie>
                  <Legend align='right' verticalAlign='middle' layout='vertical' formatter={pieLegendFormatter} />
                </PieChart>
              ) : (
                <Spin />
              )}
            </ResponsiveContainer>
          </TitleTaggedComponent>
        </div>
      </div>
    );
  },
);
