import { ContextMenuItemClickEvent, LatLng, LeafletEvent, LeafletEventHandlerFnMap } from 'leaflet';
import { MapContainer, TileLayer, useMap, useMapEvents, Popup } from 'react-leaflet';
import './index.css';
import 'leaflet/dist/leaflet.css';
// いい感じのcontextmenuをmapに拡張するためのプラグイン
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';

// カスタムマーカーアイコンの画像ファイルをインポート;
import { Camera } from 'api/cameras';
import { Typography } from 'antd';
import { CameraDetailModal } from 'components/CameraDetailModal';
import { useEffect, useState } from 'react';
import { PinLeafletIcon } from 'components/LeafletIcons';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { UrbanCameraMetaIcon } from 'components/CameraMetaIcon/UrbanCameraMetaIcon';
import CustomZoomControl from './CustomZoomControl';
import { User, usersIdPutAPI } from 'api/users';
import { joinAges } from '@shared/models/Age';
import { joinContents } from '@shared/models/Content';

/**
 *
 * @param zoom_level (leafletから取得できるzoom level 0 ~ 18)
 * @returns zoom levelに対する経度の移動量
 */
const getLongitudeShiftRelativeToZoomLevel = (zoom_level?: number) => {
  if (zoom_level === undefined || zoom_level < 0 || zoom_level > 18) return 0;
  return 0.00175 * Math.pow(2, 18 - zoom_level);
};

interface LocationMakerProps {
  handlers?: Partial<LeafletEventHandlerFnMap>;
}
function LocationMarker({ handlers }: LocationMakerProps) {
  useMapEvents({
    ...handlers,
  });

  return null;
}

interface ChangeMapCenterProps {
  position: LatLng;
}
/**
 * 中心座標の変更をleafletに伝えるコンポーネント
 */
function ChangeMapCenter({ position }: ChangeMapCenterProps) {
  const map = useMap();

  useEffect(() => {
    map.panTo(new LatLng(position.lat, position.lng));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return null;
}

export interface BaseMapProps {
  cameras?: Camera[];
  content_setting: ContentSetting;
  age_setting: AgeSetting;
  period_setting: PeriodSetting;
  device_type: string;
  home_address: number[];
  circle_color: string;
  user: User | null;
}

export function BaseMap({
  cameras,
  content_setting,
  age_setting,
  period_setting,
  device_type,
  home_address,
  circle_color,
  user,
}: BaseMapProps) {
  const [center_position, setCenterPosition] = useState<LatLng>(new LatLng(home_address[0], home_address[1]));
  const [home_position, setHomePosition] = useState<LatLng>(new LatLng(home_address[0], home_address[1]));
  const [selected_camera, setSelectedCamera] = useState<Camera | null>(null);
  const [zoom_level, setZoomLevel] = useState<number>(13);

  async function contextmenu(ev: ContextMenuItemClickEvent) {
    const latlng = ev.latlng;
    setHomePosition(latlng);
    if (user) {
      const display_content = joinContents(user?.display_content ?? undefined);
      const display_age = joinAges(user?.display_age);
      await usersIdPutAPI({ ...user, display_content, display_age, home_address: latlng.lat + ', ' + latlng.lng });
    }
  }
  function zoomend(e: LeafletEvent) {
    setZoomLevel(e.target._zoom);
  }
  function onCustomCameraMarkerClick(camera: Camera) {
    if (device_type != 'sp') {
      const { latitude, longitude } = camera;
      // スマートフォン以外の場合、選択されたカメラが詳細ダイアログに隠れないように、マップの経度を+にずらす
      const position = new LatLng(latitude, longitude + getLongitudeShiftRelativeToZoomLevel(zoom_level));
      setCenterPosition(position);
    }
    setSelectedCamera(camera);
  }
  function onCustomModalClose() {
    setSelectedCamera(null);
  }

  return (
    <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}>
      <MapContainer
        center={center_position}
        zoom={zoom_level}
        style={{ width: '100%', height: '100%', zIndex: 0, position: 'relative' }}
        zoomControl={false}
        contextmenu={true}
        contextmenuItems={[
          {
            text: 'ピン移動',
            callback: contextmenu,
          },
        ]}
      >
        <LocationMarker handlers={{ zoomend }} />
        <ChangeMapCenter position={center_position} />
        <PinLeafletIcon position={home_position} size={40}>
          <Popup>
            <Typography.Text>
              中心座標を表しています。
              <br />
              変更するにはマップ上で右クリック操作をしてください
            </Typography.Text>
          </Popup>
        </PinLeafletIcon>
        {selected_camera && (
          <CameraDetailModal
            open={!!selected_camera}
            camera={selected_camera}
            onCancel={onCustomModalClose}
            period_setting={period_setting}
            age_setting={age_setting}
            device_type={device_type}
          />
        )}
        {cameras?.map((camera) => {
          return (
            <UrbanCameraMetaIcon
              key={camera.camera_id}
              camera={camera}
              selected_camera={selected_camera}
              zoom_level={zoom_level}
              onCameraClick={onCustomCameraMarkerClick}
              content_setting={content_setting}
              age_setting={age_setting}
              period_setting={period_setting}
              circle_color={circle_color}
            />
          );
        })}
        <CustomZoomControl zoom_level={zoom_level} device_type={device_type} />
        <TileLayer
          attribution='© <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">OpenStreetMap</a>'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
      </MapContainer>
    </div>
  );
}
