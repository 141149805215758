import { sendAxios } from 'api/base/sendAxios';
import { Age } from '@shared/models/Age';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import { Unit } from '@shared/models/Unit';
import getClient from './base';
import { Gender } from '@shared/models/Gender';
import { Direction } from '@shared/models/Direction';
import { ISO8601 } from '@shared/models/ISO8601';

export type TimeMetric = {
  time: number;
  value: number;
};
export type KeyMetric = {
  key: string;
  name: string;
  value: number;
};

export interface Metrics {
  LR: {
    male: AgeGroups;
    female: AgeGroups;
  };
  RL: {
    male: AgeGroups;
    female: AgeGroups;
  };
}

export interface Countdata {
  line_no: number;
  camera_id: number;
  measure_name: string;
  time: ISO8601;
  metrics: Metrics;
}

export interface countDataGetRequest {
  camera_id: number;
  start?: ISO8601;
  end?: ISO8601;
  unit: Unit;
  line_no?: number;
  direction: string;
  data_type: 'gender' | 'age' | 'total';
}

export interface AgeGroups {
  baby: number;
  child: number;
  adult: number;
  middle: number;
  elderly: number;
}

export interface PersonData {
  time: number;
  male: AgeGroups;
  female: AgeGroups;
}

export function generatePersonData(time?: number): PersonData {
  const randomAge = () => Math.floor(Math.random() * 100) + 10; // 100から1000までのランダムな数
  const randomTime = () => time ?? Math.floor(Math.random() * (1709459200 - 1609459200) + 1609459200); // 2023年までのランダムなUNIXタイムスタンプ

  const data: PersonData = {
    time: randomTime(),
    male: {
      baby: randomAge(),
      child: randomAge(),
      adult: randomAge(),
      middle: randomAge(),
      elderly: randomAge(),
    },
    female: {
      baby: randomAge(),
      child: randomAge(),
      adult: randomAge(),
      middle: randomAge(),
      elderly: randomAge(),
    },
  };

  return data;
}

export const camera_person_data_record: Record<string, PersonData> = {};

for (let i = 1; i <= 30; i++) {
  const cameraId = i.toString().padStart(8, '0');
  camera_person_data_record[cameraId] = generatePersonData();
}

export function generatePersonDataList(num: number): PersonData[] {
  const data_list: PersonData[] = [];
  for (let i = 0; i < num; i++) {
    data_list.push(generatePersonData());
  }
  return data_list;
}

export const sumAgeGroups = (data: Partial<AgeGroups>, ages?: Age[]) => {
  let sum = 0;
  for (const [age_group, value] of Object.entries(data)) {
    if (ages) {
      if (ages.includes(age_group as Age)) {
        sum += value;
      }
    } else {
      for (const value of Object.values(data)) {
        sum += value;
      }
    }
  }
  return sum;
};

/**
 * man | womanによりPersonData[]を足し算する関数
 */
export const sumPersonDataListByGender = (sum_key: Gender, direction: Direction, ages: Age[], data?: Countdata[]) => {
  return (
    data?.reduce(function (_sum, element) {
      return _sum + sumAgeGroups(element.metrics[direction][sum_key], ages);
    }, 0) ?? 0
  );
};

/**
 * AgeGroupsによりPersonData[]を足し算する関数
 */
export const sumPersonDataListByAgeGroups = (sum_key: Age, direction: Direction, data?: Countdata[]) => {
  return (
    data?.reduce(function (_sum, element) {
      return _sum + element.metrics[direction].male[sum_key] + element.metrics[direction].female[sum_key];
    }, 0) ?? 0
  );
};

/*** [GET] /countdata/{camera_id} ***/
export interface RequestCountdataIdGet extends CommonAPIRequestType {
  camera_id: number;
  limit?: number;
  start?: ISO8601;
  end?: ISO8601;
  line_no?: number;
  unit?: Unit;
}

export const countdataCameraIdGetAPI = (args: RequestCountdataIdGet) => {
  const { camera_id, limit, start, end, unit, line_no } = args;
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `countdata/${camera_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { limit, start, end, unit, line_no };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Countdata[]>({
    axios,
    path,
    query,
    form,
    method,
  });
};
