import { Modal, ModalProps, Space, Divider, Button, Typography, Select, Checkbox } from 'antd';
import { CameraDetailPanel, UrbanInfoRef } from './CameraDetailPanel';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { Camera } from 'api/cameras';
import Icon from '@ant-design/icons';
import { MaximizeSvg } from '@shared/components/Icons/MaximizeSvg';
import { MinimizeSvg } from '@shared/components/Icons/MinimizeSvg';
import { Line } from '@shared/models/Line';
import './CameraDetailModal.css';
import React, { useMemo, useRef, useState } from 'react';
import { useBoolean } from '@shared/hooks/useBoolean/useBoolean';
import { Direction } from '@shared/models/Direction';
import { NarrowDown } from '@shared/models/NarrowDown';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';

const getResizeIcon = (is_maximum: boolean) => {
  return is_maximum ? (
    <MinimizeSvg style={{ width: 16, height: 16, color: 'rgba(0, 0, 0, 0.45)' }} />
  ) : (
    <MaximizeSvg style={{ width: 16, height: 16, color: 'rgba(0, 0, 0, 0.45)' }} />
  );
};

export interface CameraDetailModalProps extends ModalProps {
  camera: Camera;
  period_setting: PeriodSetting;
  age_setting: AgeSetting;
  device_type?: string;
}
export function CameraDetailModal(props: CameraDetailModalProps) {
  const { camera, period_setting, age_setting } = props;
  // カメラの設定を忘れていた場合のエラー回避用
  const default_line_info: Line[] = [
    {
      line_number: 0,
      line_name: '検知ライン0',
      rl_name: 'RL',
      lr_name: 'LR',
      is_setting: true,
    },
  ];
  const [totalling, setTotalling] = useState<boolean>(false);
  const [narrow_down, setNarrowDown] = useState<NarrowDown | undefined>(undefined);
  const line_info: Line[] = camera.line_info ? JSON.parse(camera.line_info) : default_line_info;
  const [line, setLine] = useState<Line>(line_info[0]);
  const { bool: is_large, onReverse } = useBoolean();
  const width = useMemo(() => {
    let result: string = '700px';
    if (is_large || props.device_type === 'sp') {
      result = 'calc(100vw - 10px * 2)';
    }
    return result;
  }, [is_large, props.device_type]);

  const height = useMemo(() => {
    let result: string = '680px';
    if (is_large) {
      result = 'calc(100vh - 10px * 2)';
    }
    if (props.device_type == 'sp') {
      result = 'calc(94vh - 10px * 2)';
    }
    return result;
  }, [is_large, props.device_type]);
  // cssにheightを伝える
  document.documentElement.style.setProperty('--height', height);

  const urban_info_items: { id: string; default_direction: Direction; ref: React.RefObject<UrbanInfoRef> }[] = [
    {
      id: '1',
      default_direction: 'LR',
      ref: useRef<UrbanInfoRef>(null),
    },
    {
      id: '2',
      default_direction: 'RL',
      ref: useRef<UrbanInfoRef>(null),
    },
  ];

  const onLineChange = (new_line: number) => {
    const selected_line = line_info.find((line) => line.line_number === new_line);
    if (selected_line !== undefined) {
      setLine(selected_line);
    }
  };

  const downloadCsv = () => {
    urban_info_items.forEach((uii) => {
      // 現在選択されている方向を取得
      const current_direction = uii.ref.current?.getCurrentDirection();
      uii.ref.current?.downloadCsv(current_direction, narrow_down);
    });
  };

  const downloadPng = () => {
    urban_info_items.forEach((uii) => {
      uii.ref.current?.downloadPng();
    });
  };

  const isDownloadLoading = () => {
    let is_loading = false;
    urban_info_items.forEach((uii) => {
      is_loading = is_loading && !!uii.ref.current?.isLoading();
    });
    return is_loading;
  };

  const onTotalCheckboxChange = () => {
    if (totalling) {
      setNarrowDown(undefined);
    }
    setTotalling(!totalling);
  };

  const onNarrowDownChange = (narrow_down: NarrowDown) => {
    setNarrowDown(narrow_down);
  };

  return (
    <Modal
      {...props}
      title={
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {camera.camera_name}
            </Typography.Title>
            <Select
              style={{ marginLeft: 20 }}
              value={line.line_number}
              onChange={onLineChange}
              options={line_info
                .filter((line) => line.is_setting === true)
                .map((line) => ({
                  value: line.line_number,
                  label: line.line_name,
                }))}
            />
          </div>
          <Divider style={{ margin: '6px 0px 6px 0px' }} />
        </>
      }
      maskClosable={false}
      footer={null}
      width={width}
      centered={false}
      mask={false}
      style={{
        position: 'fixed',
        top: 10,
        right: is_large ? undefined : 10,
        left: is_large ? 10 : undefined,
      }}
    >
      <Button
        style={{
          position: 'absolute',
          top: 17,
          right: 47,
        }}
        className='ant-modal-close'
      >
        {props.device_type != 'sp' && (
          <span className='ant-modal-close-x' onClick={onReverse}>
            <Icon
              component={() => getResizeIcon(is_large)}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            />
          </span>
        )}
      </Button>

      {props.device_type != 'sp' ? (
        <>
          <div style={{ display: 'flex', height: 'calc(100% - 1px - 5px * 2 - 32px - 35px)', width: '100%' }}>
            {urban_info_items.map((urban_info_item, i) => {
              return (
                <React.Fragment key={i}>
                  <div style={{ height: '100%', width: 'calc(50% - 5px)' }}>
                    <CameraDetailPanel
                      period_setting={period_setting}
                      age_setting={age_setting}
                      camera={camera}
                      line_info={line}
                      default_direction={urban_info_item.default_direction}
                      ref={urban_info_item.ref}
                    />
                  </div>

                  {i % 2 === 0 && <Divider type='vertical' style={{ height: '100%', fontWeight: 'bold' }} />}
                </React.Fragment>
              );
            })}
          </div>
          <Divider style={{ margin: '5px 0px' }} />
          <Space wrap style={{ height: 32 }}>
            <Button
              type='primary'
              shape='round'
              style={{ width: 180 }}
              onClick={downloadCsv}
              loading={isDownloadLoading()}
            >
              csvとしてデータ保存
            </Button>
            <Button type='primary' shape='round' style={{ width: 180 }} onClick={downloadPng}>
              画像としてデータ保存
            </Button>
          </Space>
          <div style={{ height: '35px', display: 'flex', alignItems: 'center', position: 'absolute' }}>
            <Checkbox checked={totalling} onChange={onTotalCheckboxChange}>
              集計
            </Checkbox>
            {totalling && (
              <Select
                style={{ width: 120 }}
                value={narrow_down}
                onChange={onNarrowDownChange}
                options={[
                  { value: 'direction', label: '方向' },
                  { value: 'age', label: '年齢' },
                  { value: 'gender', label: '性別' },
                ]}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div style={{ height: 'calc(100% - 1px - 5px * 2 - 70px - 35px)', width: '100%' }}>
            <React.Fragment>
              <div style={{ height: '100%', width: '100%' }}>
                <CameraDetailPanel
                  period_setting={period_setting}
                  age_setting={age_setting}
                  camera={camera}
                  line_info={line}
                  ref={null}
                />
              </div>
            </React.Fragment>
          </div>
          <Divider style={{ margin: '5px 0px' }} />
          <Space wrap style={{ height: 70 }}>
            <Button
              type='primary'
              shape='round'
              style={{ width: 180 }}
              onClick={downloadCsv}
              loading={isDownloadLoading()}
            >
              csvとしてデータ保存
            </Button>
            <Button type='primary' shape='round' style={{ width: 180 }} onClick={downloadPng}>
              画像としてデータ保存
            </Button>
          </Space>
          <div style={{ height: '35px', display: 'flex', alignItems: 'center', position: 'absolute' }}>
            <Checkbox checked={totalling} onChange={onTotalCheckboxChange}>
              集計
            </Checkbox>
            <div style={{ width: 120, marginLeft: '10px', display: totalling ? 'block' : 'none' }}>
              {totalling && (
                <Select
                  style={{ width: 120 }}
                  value={narrow_down}
                  onChange={onNarrowDownChange}
                  options={[
                    { value: 'direction', label: '方向' },
                    { value: 'age', label: '年齢' },
                    { value: 'gender', label: '性別' },
                  ]}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
